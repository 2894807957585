<template>
  <BCard body-class="p-3">
    <BOverlay
      :show="isLoadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BRow class="items-center justify-between mb-2">
        <div class="flex items-center">
          <BButton
            variant="primary"
            size="sm"
            class="mr-1"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="1x"
              icon="ChevronLeftIcon"
            />
          </BButton>
          <div class="text-black font-bold m-0 text-2xl">
            Rincian Penarikan Saldo
          </div>
        </div>
        <span :class="statusWithdrawalClass(dataWithdrawal.status)">{{ statusWithdrawal(dataWithdrawal.status) }}</span>
      </BRow>
      <BRow>
        <BCol lg="6">
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Waktu Request
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ DAY_MONTH_YEAR(dataWithdrawal.request_time) }} ({{ TIME(dataWithdrawal.request_time) }} WIB)</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Nama
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.affiliator_name }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              No HP
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.affiliator_phone_no }}</span>
            </BCol>
          </BRow>
          <BRow class="mb-[10px]">
            <BCol
              lg="4"
              class="text-black"
            >
              Nama Bank
            </BCol>
            <BCol lg="6">
              <span class="text-black font-semibold">{{ dataWithdrawal.bank_name }}</span>
            </BCol>
          </BRow>
          <BRow>
            <BCol
              lg="4"
              class="text-black"
            >
              No Rekening
            </BCol>
            <BCol
              lg="6"
              class="flex items-center"
            >
              <span class="text-black font-semibold mr-1">{{ dataWithdrawal.bank_account_no }}</span>
              <img
                v-b-tooltip.hover="`Copy nomor rekening ${dataWithdrawal.bank_account_no}`"
                src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                alt="Komerce"
                class="cursor-pointer"
                @click="toastCopyResi(dataWithdrawal.bank_account_no)"
              >
            </BCol>
          </BRow>
        </BCol>
        <BCol lg="6">
          <BCard
            style="border: 1px solid black"
            body-class="p-1 max-h-[30vh] overflow-auto"
          >
            <div class="text-black font-medium text-xl mb-2">
              Ringkasan Info
            </div>
            <div class="text-black text-xl font-medium my-1">
              Kesamaan Akun
            </div>
            <BRow class="mb-[10px]">
              <BCol class="text-black">
                Nama Pemilik Bank :
              </BCol>
            </BRow>
            <div v-if="!isEmpty(dataWithdrawal.account_check.exist_by_acc_name)">
              <div
                v-for="(account,idx) in dataWithdrawal.account_check.exist_by_acc_name"
                :key="idx"
              >
                <BRow class="mb-[10px]">
                  <BCol>
                    <li class="text-black ml-1">
                      {{ account.email }}
                    </li>
                  </BCol>
                </BRow>
                <BRow class="mb-[10px]">
                  <BCol class="ml-1 flex items-center">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg"
                      alt="Komerce"
                      class="mr-[10px]"
                    >
                    <BFormInput
                      :value="IDR(account.kmpoin)"
                      readonly
                      :style="handleColorBank(account.kmpoin)"
                    />
                  </BCol>
                </BRow>
              </div>
            </div>
            <li
              v-else
              class="ml-1 mb-1 text-black"
            >
              (Belum ada kesamaan dengan akun lain)
            </li>
            <BRow class="mb-[10px]">
              <BCol class="text-black">
                Nomor Rekening Bank :
              </BCol>
            </BRow>
            <div v-if="!isEmpty(dataWithdrawal.account_check.exist_by_acc_no)">
              <div
                v-for="(account, idx) in dataWithdrawal.account_check.exist_by_acc_no"
                :key="idx"
              >
                <BRow class="mb-[10px]">
                  <BCol>
                    <li class="text-black ml-1">
                      {{ account.email }}
                    </li>
                  </BCol>
                </BRow>
                <BRow class="mb-[10px]">
                  <BCol class="ml-1 flex items-center">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg"
                      alt="Komerce"
                      class="mr-[10px]"
                    >
                    <BFormInput
                      :value="IDR(account.kmpoin)"
                      readonly
                      :style="handleColorBank(account.kmpoin)"
                    />
                  </BCol>
                </BRow>
              </div>
            </div>
            <li
              v-else
              class="ml-1 text-black"
            >
              (Belum ada kesamaan dengan akun lain)
            </li>
          </BCard>
        </BCol>
      </BRow>
      <BRow class="mt-1">
        <BCol
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Saldo Terkini
            </div>
            <span class="font-semibold text-2xl text-[#F95031]">
              {{ IDR(dataWithdrawal.commission) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Request Penarikan
            </div>
            <span class="font-semibold text-2xl text-[#34A770]">
              {{ IDR(dataWithdrawal.request_nominal) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          :lg="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review' ? 3 : 4"
          class="mb-1"
        >
          <BCard class="balance">
            <div class="mb-1 text-black">
              Sisa Jika Disetujui
            </div>
            <span class="font-semibold text-2xl text-[#08A0F7]">
              {{ IDR(dataWithdrawal.commission_when_accepted) }}
            </span>
          </BCard>
        </BCol>
        <BCol
          v-if="dataWithdrawal.status === 'requested' || dataWithdrawal.status === 'on_review'"
          lg="3"
          class="flex flex-column justify-center mb-1"
        >
          <div class="flex">
            <BButton
              id="popoverStatus"
              variant="outline-primary"
            >
              {{ filters.label }}
            </BButton>
            <BPopover
              id="popover-filter-type"
              target="popoverStatus"
              triggers="focus"
              placement="bottom"
            >
              <div
                v-for="(data, index) in chooseAction"
                :key="index+1"
              >
                <BButton
                  v-model="data.value"
                  variant="flat-dark"
                  @click="setActions(data)"
                >
                  {{ data.label }}
                </BButton>
              </div>
            </BPopover>
            <div class="ml-1">
              <BButton
                variant="primary"
                @click="handleActions"
              >
                Terapkan
              </BButton>
            </div>
          </div>
        </BCol>
      </BRow>
    </BOverlay>
    <div
      v-if="dataWithdrawal.notes !== ''"
      class="mt-1"
    >
      <div class="flex">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/edit.svg"
          alt="Komerce"
        >
        <span class="text-black font-bold text-start ms-2">
          Catatan :
        </span>
      </div>
      <div class="mt-1 notes">
        {{ isNotesNotification || '-' }}
      </div>
    </div>
    <BOverlay
      :show="isLoadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-2"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
      >
        <template #cell(timestamp)="value">
          {{ DAY_MONTH_YEAR(value.item.timestamp) }}
          <br>
          <span class="text-[#828282]">{{ TIME(value.item.timestamp) }}</span>
        </template>
        <template #cell(saldo)="value">
          <span class="font-semibold">{{ IDR(value.item.saldo) }}</span>
        </template>
        <template #cell(nominal)="value">
          <span :class="classNominal(value.item.mutation_type)">{{ labelNominal(value.item.nominal, value.item.mutation_type) }}</span>
        </template>
        <template #cell(mutation_description)="value">
          <span :class="mutationDescription(value.item.mutation_description)">{{ value.item.mutation_description }}</span>
          <!-- {{ mutationDescription(value.item.mutation_description) }} -->
        </template>
      </BTable>
      <BRow>
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="bg-light d-flex justify-content-center align-items-center p-50 rounded">
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
    <BRow class="justify-between mt-2">
      <h3 class="text-black font-semibold text-3xl ml-1">
        Saldo Awal :
      </h3>
      <h3
        style="color: #F95031"
        class="text-black font-semibold text-3xl mr-5"
      >
        {{ IDR(dataWithdrawal.beginning_balance) }}
      </h3>
    </BRow>

    <ModalTransferNow :get-detail-withdrawal="getDetailWithdrawal" />
    <ModalReview :get-detail-withdrawal="getDetailWithdrawal" />
    <ModalReject :get-detail-withdrawal="getDetailWithdrawal" />
  </BCard>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import ModalTransferNow from './ModalTransferNow.vue'
import ModalReview from './ModalReview.vue'
import ModalReject from './ModalReject.vue'
import { listDetailWithdrawal, chooseAction } from './config'

export default {
  components: { ModalTransferNow, ModalReview, ModalReject },
  data() {
    return {
      isLoadingTable: false,
      isLoading: false,
      dataWithdrawal: {},
      filters: {
        label: 'Pilih Tindakan',
        value: '',
      },
      chooseAction,
      items: [],
      fields: listDetailWithdrawal,
      idWithdrawal: this.$route.params.id,
      DAY_MONTH_YEAR,
      TIME,
      IDR,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [20, 50, 100, 200, 400],
      totalPerPage: 20,
      isEmpty,
      isNotesNotification: '',
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getDetailWithdrawal()
      },
    },
  },
  mounted() {
    this.getDetailWithdrawal()
  },
  methods: {
    async getDetailWithdrawal() {
      this.isLoading = true
      const url = `/v1/withdrawal/admin/detail/${this.idWithdrawal}`
      await affiliateAxiosIns.get(url)
        .then(async res => {
          const { data } = res.data
          this.dataWithdrawal = data
          this.isLoading = false
          // this.isLoadingTable = false

          const params = {
            per_page: this.totalPerPage,
            page: this.currentPage,
            withdrawal_id: this.idWithdrawal,
          }
          const urlList = `/v1/transaction/admin/history/${params.withdrawal_id}`
          await affiliateAxiosIns.get(urlList, { params })
            .then(response => {
              const { data: { notes } } = response.data
              this.isNotesNotification = notes
              this.items = response.data.data.data
              console.log(this.items)
              this.isLoadingTable = false
              this.totalRows = response.data.data.total
            })
            .catch(err => {
              this.$toast(
                {
                  component: ToastificationContentVue,
                  props: {
                    title: 'Failure',
                    icon: 'AlertCircleIcon',
                    text: err.message,
                    variant: 'danger',
                  },
                },
                2000,
              )
              this.isLoadingTable = false
            })
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.getDetailWithdrawal()
    },
    setActions(data) {
      this.filters = data
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    handleActions() {
      if (this.filters.value === 'transfer_now') return this.$bvModal.show('modal-transfer-now')
      if (this.filters.value === 'review') return this.$bvModal.show('modal-review')
      if (this.filters.value === 'tolak') return this.$bvModal.show('modal-reject')
      if (this.filters.value === 'transfer_manual') {
        return this.$router.push({ path: `/affiliate/withdrawal/${this.idWithdrawal}/transfer` })
      }

      return ''
    },
    statusWithdrawal(status) {
      if (status === 'reject') return 'Ditolak'
      if (status === 'requested') return 'Request Penarikan'
      if (status === 'accepted') return 'Disetujui'
      if (status === 'on_review') return 'Sedang Direview'
      if (status === 'in_process') return 'Diproses'
      return ''
    },
    statusWithdrawalClass(status) {
      if (status === 'reject') return 'rejected'
      if (status === 'requested') return 'requested'
      if (status === 'on_review') return 'on_review'
      if (status === 'accepted') return 'completed'
      if (status === 'in_process') return 'in_process'
      return ''
    },
    labelTransactionType(value) {
      const { description, order_payment_method } = value
      if (description === 'orderku_ongkir') return 'Orderan Non-COD (Ongkir)'
      if (description === 'orderku_cancel') return 'Orderan Non-COD (Cancel)'
      if (description === 'withdrawal') return 'Penarikan Saldo'
      if (description === 'shopping') return 'Belanja Keperluan Talent'
      if (description === 'shopping') return 'Belanja Keperluan Talent'
      if (description === 'topup') return 'Top Up Saldo'
      // eslint-disable-next-line camelcase
      if (description === 'orderku_retur' && order_payment_method === 'COD') return 'Orderan COD (Retur)'
      // eslint-disable-next-line camelcase
      if (description === 'orderku_done' && order_payment_method === 'COD') return 'Orderan COD (Selesai)'
      // eslint-disable-next-line camelcase
      if (description === 'orderku_retur' && order_payment_method === 'BANK TRANSFER') return 'Orderan Non-COD (Retur)'
      // eslint-disable-next-line camelcase
      if (description === 'orderku_done' && order_payment_method === 'BANK TRANSFER') return 'Orderan Non-COD (Selesai)'
      if (description === 'orderku_done') return 'Orderan Non-COD (Selesai)'
      return ''
    },
    mutationDescription(mutationDescription) {
      if (mutationDescription === 'Penarikan saldo') return 'penarikan-saldo'
      if (mutationDescription === 'Orderan selesai') return 'orderan-selesai'
      return ''
    },
    labelNominal(nominal, mutationType) {
      if (mutationType === 'credit') return `+ ${IDR(nominal)}`
      if (mutationType === 'debit') return `- ${IDR(nominal)}`
      return ''
    },
    classNominal(type) {
      if (type === 'credit') return 'typeGreen'
      if (type === 'debit') return 'typeRed'
      return ''
    },
    onRowSelected(data) {
      const { description, order_id } = data
      if (description === 'orderku_ongkir' || description === 'orderku_done' || description === 'orderku_done' || description === 'orderku_retur') {
        const url = this.$router.resolve({
          // eslint-disable-next-line camelcase
          path: `/tiket/detail/order/${order_id}`,
        })
        window.open(url.href, '_blank')
      }
    },
    toastCopyResi(number) {
      navigator.clipboard.writeText(number)
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: `${number} berhasil disalin`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        },
        1000,
      )
    },
    handleColorBank(number) {
      if (number < 0) { return 'color: #E31A1A' }
      return 'color: #34A770'
    },
  },
}
</script>

<style scoped lang="scss">
@import './Withdrawal.scss';
</style>
