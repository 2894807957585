<template>
  <BModal
    id="modal-review"
    ref="modal-review"
    no-stacking
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    size="lg"
    hide-header
  >
    <div class="mb-1 text-black text-semibold text-xl">
      Buat catatan review penarikan
    </div>
    <BFormGroup
      label=""
      label-for="catatan-penarikan-textarea"
      invalid-feedback="Silahkan isi catatan"
    >
      <BFormTextarea
        v-model="notes"
        placeholder="Berikan catatan kamu untuk partner dalam penarikan"
        rows="8"
        required
        :formatter="validMaxLength"
      />
      <BRow class="justify-content-end">
        <small class="mr-1 mt-50">
          {{ notes.length }}/250
        </small>
      </BRow>
    </BFormGroup>
    <div class="w-100 flex justify-end">
      <BButton
        variant="outline-primary"
        class="mr-1 text-center"
        @click="closeModal"
      >
        Batal
      </BButton>
      <BButton
        variant="primary"
        class="text-center"
        :disabled="checkValidityDisabled() || disabled"
        @click="onFinish"
      >
        <BSpinner
          v-if="isLoading"
          variant="light"
          small
        />
        Review & Submit
      </BButton>
    </div>

  </BModal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { affiliateAxiosIns } from '@/libs/axios'

export default {
  props: {
    getDetailWithdrawal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      notes: '',
      idWithdrawal: this.$route.params.id,
      isLoading: false,
      disableBtn: false,
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      this.disableBtn = true
      const url = `/v1/withdrawal/admin/action/${this.idWithdrawal}`
      const payload = {
        action: 3,
        notes: this.notes,
      }

      await affiliateAxiosIns.put(url, payload)
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Withdrawal Berhasil DiReview',
              variant: 'success',
            },
          }, { timeout: 1000 })
          this.$bvModal.hide('modal-review')
          this.getDetailWithdrawal()
          this.isLoading = false
          this.disableBtn = true
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
          this.isLoading = false
          this.disableBtn = true
        })
    },
    closeModal() {
      this.notes = ''
      this.$nextTick(() => {
        this.$bvModal.hide('modal-review')
      })
    },
    validMaxLength(e) {
      return String(e).substring(0, 250)
    },
    checkValidityDisabled() {
      if (this.notes.length === 0) {
        return true
      }
      return false
    },
  },
}
</script>
